import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { style: {"width":"fit-content !important"} }
const _hoisted_2 = { class: "el-padding__left el-padding__right el-text__white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_badge = _resolveComponent("el-badge")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_table, {
      ref: "drilldownGrid",
      border: "",
      stripe: true,
      "max-height": "400",
      "summary-method": _ctx.getSummaries,
      "row-key": "id",
      "expand-row-keys": _ctx.expandRowKeys,
      data: _ctx.drilldownResponse.rows,
      "highlight-current-row": true,
      onSortChange: _ctx.changeSort,
      onExpandChange: _ctx.toggleDrilldown
    }, {
      empty: _withCtx(() => [
        _createVNode(_component_el_empty, { description: "No Data" })
      ]),
      default: _withCtx(() => [
        (_ctx.drilldownNextLevel)
          ? (_openBlock(), _createBlock(_component_el_table_column, {
              key: 0,
              type: "expand"
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "drilldown", { dataRow: _ctx.dataRow })
              ]),
              _: 3
            }))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.drilldown.columns, (column, index) => {
          return (_openBlock(), _createBlock(_component_el_table_column, {
            key: index,
            prop: column.id,
            label: column.header_text,
            width: column.width,
            sortable: "custom",
            "sort-orders": ['ascending', 'descending'],
            "class-name": `el-text__${column.column_align}`
          }, {
            header: _withCtx(() => [
              _createTextVNode(_toDisplayString(column.header_text) + " ", 1),
              _withDirectives(_createVNode(_component_el_button, {
                icon: "el-icon-caret-top",
                type: "text",
                class: "el-text__white"
              }, null, 512), [
                [_vShow, _ctx.sortOrder.order === 'ascending' && _ctx.sortOrder.prop === column.id]
              ]),
              _withDirectives(_createVNode(_component_el_button, {
                icon: "el-icon-caret-bottom",
                type: "text",
                class: "el-text__white"
              }, null, 512), [
                [_vShow, _ctx.sortOrder.order === 'descending' && _ctx.sortOrder.prop === column.id]
              ])
            ]),
            default: _withCtx((scope) => [
              (column.type === 'date')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(scope.row[column.id]
                ? _ctx.moment.utc(scope.row[column.id]).format("MM-DD-YYYY")
                : ''), 1)
                  ], 64))
                : (column.type === 'money')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(scope.row[column.id] ?
                new Intl.NumberFormat(
                  'en-US', { style: 'currency', currency: 'USD' }
                ).format(scope.row[column.id])
                :
                ''), 1)
                    ], 64))
                  : (column.id.startsWith('alert'))
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                        (scope.row[column.id] && scope.row[column.id] > 0)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _createVNode(_component_font_awesome_icon, {
                                class: "el-text__danger",
                                icon: "exclamation-triangle"
                              }),
                              _createVNode(_component_el_badge, {
                                value: scope.row[column.id],
                                class: "el-text__danger el-text__small",
                                type: "danger"
                              }, null, 8, ["value"])
                            ], 64))
                          : _createCommentVNode("", true)
                      ], 64))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                        _createTextVNode(_toDisplayString(column.prefix && scope.row[column.id] ? column.prefix : '') + " " + _toDisplayString(scope.row[column.id]), 1)
                      ], 64))
            ]),
            _: 2
          }, 1032, ["prop", "label", "width", "class-name"]))
        }), 128))
      ]),
      _: 3
    }, 8, ["summary-method", "expand-row-keys", "data", "onSortChange", "onExpandChange"]),
    _createVNode(_component_el_row, {
      type: "flex",
      justify: "space-between"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          span: 22,
          class: "el-background__primary"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              class: "el-padding__left",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changePage(0))),
              type: "primary",
              icon: "el-icon-d-arrow-left"
            }),
            _createVNode(_component_el_button, {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changePage(_ctx.drilldownResponse.pagination.page_index - 1))),
              type: "primary",
              icon: "el-icon-arrow-left"
            }),
            _createElementVNode("small", _hoisted_2, [
              _createTextVNode(" Records " + _toDisplayString(_ctx.drilldownResponse.pagination.page_index === 0
              ? 1
              : (_ctx.drilldownResponse.pagination.page_size * _ctx.drilldownResponse.pagination.page_index)) + " to ", 1),
              ((
              _ctx.drilldownResponse.pagination.page_count - 1
            ) === _ctx.drilldownResponse.pagination.page_index)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(_ctx.drilldownResponse.pagination.total_rows), 1)
                  ], 64))
                : (_ctx.drilldownResponse.pagination.page_index === 0)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(_ctx.drilldownResponse.pagination.page_size), 1)
                    ], 64))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      _createTextVNode(_toDisplayString(_ctx.drilldownResponse.pagination.page_size * (
              _ctx.drilldownResponse.pagination.page_index + 1
            )), 1)
                    ], 64)),
              _createTextVNode(" of " + _toDisplayString(_ctx.drilldownResponse.pagination.total_rows) + " records ", 1)
            ]),
            _createVNode(_component_el_button, {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changePage(_ctx.drilldownResponse.pagination.page_index + 1))),
              disabled: _ctx.canChangePage,
              type: "primary",
              icon: "el-icon-arrow-right"
            }, null, 8, ["disabled"]),
            _createVNode(_component_el_button, {
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changePage(_ctx.drilldownResponse.pagination.page_count - 1))),
              disabled: _ctx.canChangePage,
              type: "primary",
              icon: "el-icon-d-arrow-right"
            }, null, 8, ["disabled"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 2 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select, {
              class: "el-background__success",
              modelValue: _ctx.page_size,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.page_size) = $event)),
              onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.changeLimit(_ctx.page_size)))
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (page, index) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: index,
                    label: page,
                    value: page
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}