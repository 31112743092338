import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ColumnsTab = _resolveComponent("ColumnsTab")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_FiltersTab = _resolveComponent("FiltersTab")!
  const _component_AdvancedFiltersTab = _resolveComponent("AdvancedFiltersTab")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    title: "Customize View",
    modelValue: _ctx.isDialogOpen,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isDialogOpen) = $event)),
    onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isDialogOpen = false, _ctx.$emit('query-grid-data')))
  }, {
    footer: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: _ctx.resetToDefault,
          icon: "el-icon-refresh"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" reset to default ")
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_el_button, {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isDialogOpen = false)),
          icon: "el-icon-close"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Cancel ")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "success",
          onClick: _ctx.saveConfigurations,
          icon: "el-icon-check"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Apply ")
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_tabs, {
        ref: "customizeTab",
        modelValue: _ctx.activeTab,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event)),
        onTabClick: _ctx.handleTabClick
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_tab_pane, {
            label: "Columns",
            name: "columns"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ColumnsTab, {
                ref: "columnsTab",
                columns: _ctx.columns
              }, null, 8, ["columns"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_tab_pane, {
            label: "Filters",
            name: "filters"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_FiltersTab, { ref: "filtersTab" }, null, 512)
            ]),
            _: 1
          }),
          _createVNode(_component_el_tab_pane, {
            label: "Advanced Filters",
            name: "advancedFilters"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AdvancedFiltersTab, { ref: "advancedFiltersTab" }, null, 512)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue", "onTabClick"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}