import { useStore } from 'vuex';
import { computed, ComputedRef } from 'vue';

import { IExportRequest, IExportResponse } from '@/interfaces/Export';
import { IFilter } from '@/interfaces/Filter';
import { IOrderRequest } from '@/interfaces/Order';

export default function useExport(): {
  exportResponse: ComputedRef<IExportResponse>;
  exportRequest: ComputedRef<IExportRequest>;
  resetExportRequest(): void;
  setExportRequestFilters(payload: IFilter[]): void;
  setExportRequestOrders: (payload: IOrderRequest[]) => void;
  setExportRequestPageSize: (payload: number) => void;
  fetchExportData: (payload: {
    viewName: string;
    gridName: string;
    exportRequest: IExportRequest;
  }) => Promise<void>;
  } {
  const store = useStore();

  const exportRequest = computed((): IExportRequest => store.getters['Export/getExportRequest']);

  const exportResponse = computed((): IExportResponse => store.getters['Export/getExportResponse']);

  function resetExportRequest(): void {
    store.commit('Export/RESET_EXPORT_REQUEST');
  }

  function setExportRequestFilters(payload: IFilter[]): void {
    store.commit('Export/SET_EXPORT_REQUEST_FILTERS', payload);
  }

  function setExportRequestOrders(payload: IOrderRequest[]): void {
    store.commit('Export/SET_EXPORT_REQUEST_ORDERS', payload);
  }

  function setExportRequestPageSize(payload: number): void {
    store.commit('Export/SET_EXPORT_REQUEST_PAGE_SIZE', payload);
  }

  async function fetchExportData(
    payload: { viewName: string; gridName: string; exportRequest: IExportRequest },
  ): Promise<void> {
    try {
      await store.dispatch('Export/fetchExportData', payload);
    } catch (error) {
      console.error(error);
    }
  }

  return {
    exportResponse,
    exportRequest,
    fetchExportData,
    resetExportRequest,
    setExportRequestFilters,
    setExportRequestOrders,
    setExportRequestPageSize,
  };
}
