import { useStore } from 'vuex';
import { computed, ComputedRef } from 'vue';
import {
  IColumnRequest, IColumnResponse,
} from '@/interfaces/Column';

export default function useColumn(): {
  columnResponse: ComputedRef<IColumnResponse>;
  resetColumnResponse: () => void;
  fetchColumnData: (payload: IColumnRequest) => Promise<void>;
  } {
  const store = useStore();

  const columnResponse = computed((): IColumnResponse => store.getters['Column/getColumnResponse']);

  function resetColumnResponse(): void {
    store.commit('Column/RESET_COLUMN_RESPONSE');
  }

  async function fetchColumnData(payload: IColumnRequest): Promise<void> {
    try {
      await store.dispatch('Column/fetchColumnData', payload);
    } catch (error) {
      console.error(error);
    }
  }

  return {
    columnResponse,
    resetColumnResponse,
    fetchColumnData,
  };
}
