import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_Chart = _resolveComponent("Chart")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_DataGrid = _resolveComponent("DataGrid")!
  const _component_DataGridPagination = _resolveComponent("DataGridPagination")!
  const _component_DataGridDrawer = _resolveComponent("DataGridDrawer")!
  const _component_CustomizeDialog = _resolveComponent("CustomizeDialog")!
  const _component_InvoiceNotesDialog = _resolveComponent("InvoiceNotesDialog")!
  const _component_FavoritesFormDialog = _resolveComponent("FavoritesFormDialog")!
  const _component_DashboardLayout = _resolveComponent("DashboardLayout")!

  return (_openBlock(), _createBlock(_component_DashboardLayout, null, {
    header: _withCtx(() => [
      (_ctx.view.name)
        ? (_openBlock(), _createBlock(_component_PageHeader, {
            key: 0,
            ref: "pageHeader",
            title: _ctx.view.display_props.name,
            "search-definition": _ctx.view.search,
            onOpenCustomizeDialog: _ctx.openCustomizeDialog,
            onOpenFavoritesFormDialog: _ctx.openFavoritesFormDialog,
            onQueryGridData: _ctx.queryGridData,
            onExportXlsx: _ctx.exportXlsx
          }, null, 8, ["title", "search-definition", "onOpenCustomizeDialog", "onOpenFavoritesFormDialog", "onQueryGridData", "onExportXlsx"]))
        : _createCommentVNode("", true)
    ]),
    content: _withCtx(() => [
      (_ctx.view.components && _ctx.view.components.length > 0)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.charts)
              ? (_openBlock(), _createBlock(_component_el_row, { key: 0 }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.charts, (chart, chartIndex) => {
                      return (_openBlock(), _createBlock(_component_el_col, {
                        key: chartIndex,
                        xs: 24,
                        sm: 24,
                        md: 12,
                        lg: 12,
                        xl: 12
                      }, {
                        default: _withCtx(() => [
                          (chart.definition?.series && chart.definition?.series.length > 0)
                            ? (_openBlock(), _createBlock(_component_Chart, {
                                key: 0,
                                "chart-config": chart.definition,
                                "chart-name": chart.name
                              }, null, 8, ["chart-config", "chart-name"]))
                            : (_openBlock(), _createBlock(_component_el_empty, {
                                key: 1,
                                description: "No Data Found"
                              }))
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.gridName)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _withDirectives(_createVNode(_component_el_button, {
                    type: "info",
                    icon: "el-icon-close",
                    onClick: _ctx.resetFilters
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" reset filters ")
                    ]),
                    _: 1
                  }, 8, ["onClick"]), [
                    [_vShow, _ctx.gridRequest.filters &&
              _ctx.gridRequest.filters.filter((filter) => filter.visible).length > 0
            ]
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, (action, index) => {
                    return _withDirectives((_openBlock(), _createBlock(_component_el_button, {
                      key: index,
                      type: "success",
                      onClick: ($event: any) => (_ctx.triggerDynamicAction(action.command))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(action.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])), [
                      [_vShow, 
              _ctx.actions &&
                _ctx.dataGrid &&
                _ctx.dataGrid.multipleSelection && _ctx.dataGrid.multipleSelection.length > 0
            ]
                    ])
                  }), 128)),
                  _createVNode(_component_DataGrid, {
                    id: "dataGrid",
                    ref: "dataGrid",
                    columns: _ctx.columns ? _ctx.columns : [],
                    totals: _ctx.grid.totals,
                    "grid-data": _ctx.grid.rows ? _ctx.grid.rows : [],
                    drilldowns: _ctx.view.drilldowns,
                    "drill-through": _ctx.drillThrough,
                    "has-notes": _ctx.hasNotes,
                    filters: _ctx.gridRequest.filters ? _ctx.gridRequest.filters.filter((el) => el.visible) : [],
                    onToggleDrawer: _ctx.toggleDrawer,
                    onSortChange: _ctx.changeSort,
                    onAddFilter: _ctx.addFilter,
                    onRemoveFilter: _ctx.removeFilter,
                    onRefreshData: _ctx.refreshData,
                    onResetColumnFilters: _ctx.resetColumnFilters
                  }, null, 8, ["columns", "totals", "grid-data", "drilldowns", "drill-through", "has-notes", "filters", "onToggleDrawer", "onSortChange", "onAddFilter", "onRemoveFilter", "onRefreshData", "onResetColumnFilters"]),
                  _createVNode(_component_DataGridPagination, {
                    pagination: _ctx.grid.pagination,
                    onChangePage: _ctx.changePage,
                    onChangeLimit: _ctx.changeLimit
                  }, null, 8, ["pagination", "onChangePage", "onChangeLimit"]),
                  _createVNode(_component_DataGridDrawer, {
                    details: _ctx.details,
                    ref: "dataGridDrawer"
                  }, null, 8, ["details"]),
                  _createVNode(_component_CustomizeDialog, {
                    ref: "customizeDialog",
                    name: _ctx.view.name,
                    description: _ctx.view.description,
                    columns: _ctx.columns,
                    filters: _ctx.gridRequest.filters ? _ctx.gridRequest.filters.filter((el) => el.visible) : [],
                    onQueryGridData: _ctx.queryGridData,
                    onResetDataGrid: _ctx.resetDataGrid
                  }, null, 8, ["name", "description", "columns", "filters", "onQueryGridData", "onResetDataGrid"]),
                  _createVNode(_component_InvoiceNotesDialog, {
                    ref: "invoiceNotesDialog",
                    onSaveNote: _ctx.saveNote
                  }, null, 8, ["onSaveNote"]),
                  _createVNode(_component_FavoritesFormDialog, {
                    ref: "favoritesFormDialog",
                    onSaveFavorite: _ctx.saveFavorite
                  }, null, 8, ["onSaveFavorite"])
                ], 64))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}