
import {
  computed, defineComponent, Ref, ref,
} from 'vue';
import { ElAutocomplete, ElTabs } from 'element-plus';

import moment from 'moment';

import useColumn from '@/uses/useColumn';
import useGrid from '@/uses/useGrid';
import useNote from '@/uses/useNote';
import useView from '@/uses/useView';

import { IColumn } from '@/interfaces/View';
import { IFilter } from '@/interfaces/Filter';
import { IColumnRequest } from '@/interfaces/Column';

export default defineComponent({
  name: 'FiltersTab',

  setup() {
    const { columnResponse, fetchColumnData } = useColumn();

    const {
      gridRequest, addGridRequestFilter, removeGridRequestFilter,
    } = useGrid();

    const {
      columns, dataSource, gridName, view,
    } = useView();

    const { queryNote, noteQueryResponse } = useNote();

    const columnQueryValue = ref<string>('');

    const activeColumn = ref<string>('');

    const operation = ref<string>('');

    // this is the type of an object that contains
    // references to the inputs of the columns
    interface FilterInputRef {
      [key: string]: Ref<InstanceType<typeof ElAutocomplete>>;
    }

    // this is is an object whose keys contain the references to all the different
    // column filter inputs
    const filterInputs = ref<FilterInputRef>({});

    // this is the ref of the tab
    const tabRef = ref<InstanceType<typeof ElTabs>>();

    const onTabChange = (): void => {
      // if tab ref has a value focus on the filter input
      // with its current name
      if (tabRef.value) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        filterInputs.value[tabRef.value.currentName].focus();
      }
    };

    const columnRequest = ref<IColumnRequest>({
      dataset_name: dataSource.value,
      dimensions: [{
        name: '',
      }],
      metrics: [],
      filters: [{
        name: '',
        operation: 'like',
        value: '',
        type: '',
      }],
      order: [],
      pagination: {
        page_size: 100,
        page_index: 0,
      },
    });

    const setColumnRequest = (): void => {
      const selectedColumn = columns.value.find(
        (column: IColumn) => column.id === activeColumn.value,
      );
      columnRequest.value.dimensions[0].name = activeColumn.value;
      columnRequest.value.filters[0].name = activeColumn.value;
      columnRequest.value.filters[0].type = selectedColumn ? selectedColumn.type : '';
      columnRequest.value.filters[0].value = columnQueryValue.value;
      if (selectedColumn && selectedColumn.type === 'money') {
        operation.value = 'in';
      } else if (selectedColumn && selectedColumn.type === 'date') {
        operation.value = 'in';
      } else {
        operation.value = 'like';
      }
    };

    const queryColumnData = async (queryString: string, cb) => {
      if (activeColumn.value === 'notes') {
        await queryNote({
          viewName: view.value.name,
          componentName: gridName.value,
          note: columnQueryValue.value,
        });
        cb(noteQueryResponse.value.rows?.map((value) => ({
          value: Object.values(value)[0],
          label: Object.values(value)[0],
        })));
      } else {
        setColumnRequest();
        await fetchColumnData(columnRequest.value);
        cb(columnResponse.value.rows?.map((value) => ({
          value: Object.values(value)[0],
          label: Object.values(value)[0],
        })));
      }
    };

    const filters = computed(
      (): IFilter[] => gridRequest.value.filters.filter(
        (el) => el.id === activeColumn.value,
      ),
    );

    const addFilter = (selectedValue): void => {
      const column = columns.value.find(
        (el) => el.id === activeColumn.value,
      );
      const filter: IFilter = {
        id: activeColumn.value,
        type: column ? column.type : '',
        index: 0,
        visible: true,
        field_id: activeColumn.value,
        operation: operation.value,
        data_source: '',
        filter_text: selectedValue.value,
        header_text: column ? column.header_text : '',
        default_filter: '',
      };
      addGridRequestFilter(filter);
      columnQueryValue.value = '';
    };

    const removeFilter = (payload: IFilter): void => {
      removeGridRequestFilter(payload);
    };
    return {
      columns,
      tabRef,
      columnQueryValue,
      columnRequest,
      activeColumn,
      filters,
      filterInputs,
      moment,
      queryColumnData,
      addFilter,
      removeFilter,
      onTabChange,
    };
  },
});
