
import { defineComponent, ref } from 'vue';

export default defineComponent({
  note: 'NotesFormDialog',

  emits: ['save-note'],

  setup() {
    const note = ref<string>('');

    const isDialogOpen = ref<boolean>(false);

    const open = ():void => {
      isDialogOpen.value = true;
    };

    const close = ():void => {
      isDialogOpen.value = false;
    };

    return {
      note,
      isDialogOpen,
      open,
      close,
    };
  },
});
