
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'DataGridDrawer',

  props: {
    details: {
      type: Object,
      required: false,
      default: null,
    },
  },

  setup() {
    const isDrawerOpen = ref<boolean>(false);

    const direction = ref<string>('rtl');

    const activeItem = ref<string>('details');

    const open = (): void => {
      isDrawerOpen.value = true;
    };

    return {
      isDrawerOpen,
      direction,
      activeItem,
      open,
    };
  },
});
