
import {
  computed, defineComponent, onMounted, ref,
} from 'vue';

import useDataset from '@/uses/useDataset';
import useGrid from '@/uses/useGrid';
import useView from '@/uses/useView';

import { IFilter } from '@/interfaces/Filter';

export default defineComponent({
  name: 'AdvancedFiltersTab',

  setup() {
    const {
      gridRequest, addGridRequestFilter, removeGridRequestFilter,
    } = useGrid();

    const { operations, fetchDatasetOperations } = useDataset();

    const { columns } = useView();

    const filter = ref<IFilter>({
      id: '',
      type: '',
      index: 0,
      visible: true,
      field_id: '',
      operation: '',
      data_source: '',
      filter_text: '',
      header_text: '',
      default_filter: '',
    });

    onMounted(async (): Promise<void> => {
      try {
        await fetchDatasetOperations();
      } catch (error) {
        console.error(error);
      }
    });

    const canAddFilter = computed((): boolean => (
      !filter.value.operation
      || !filter.value.id
       || !filter.value.filter_text
    ));

    const addFilter = (): void => {
      try {
        const column = columns.value.find(
          (el) => el.id === filter.value.id,
        );

        filter.value = {
          ...filter.value,
          type: column ? column.type : '',
          visible: true,
          field_id: column ? column.id : '',
          data_source: '',
          header_text: column ? column.header_text : '',
          default_filter: '',
        };

        addGridRequestFilter(filter.value);
      } catch (error) {
        console.error(error);
      } finally {
        filter.value = {
          id: '',
          type: '',
          index: 0,
          visible: true,
          field_id: '',
          operation: '',
          data_source: '',
          filter_text: '',
          header_text: '',
          default_filter: '',
        };
      }
    };

    const removeFilter = (payload: IFilter): void => {
      removeGridRequestFilter(payload);
    };
    return {
      columns,
      gridRequest,
      operations,
      filter,
      canAddFilter,
      addFilter,
      removeFilter,
    };
  },
});
