import { createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_link = _resolveComponent("el-link")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")!
  const _component_el_collapse = _resolveComponent("el-collapse")!
  const _component_el_drawer = _resolveComponent("el-drawer")!

  return (_openBlock(), _createBlock(_component_el_drawer, {
    title: "Detailed View",
    modelValue: _ctx.isDrawerOpen,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isDrawerOpen) = $event)),
    direction: _ctx.direction,
    size: "25%"
  }, {
    title: _withCtx(() => [
      _createTextVNode(" Detailed View ")
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_collapse, {
        modelValue: _ctx.activeItem,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeItem) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_collapse_item, {
            title: "Details",
            name: "details"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_scrollbar, { "max-height": "400px" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(_ctx.details), (entry, index) => {
                    return (_openBlock(), _createElementBlock("div", { key: index }, [
                      _createVNode(_component_el_link, {
                        type: "info",
                        class: "el-capitalize"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(entry[0]), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createElementVNode("p", null, _toDisplayString(entry[1]), 1)
                    ]))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["modelValue", "direction"]))
}