import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_info_filled = _resolveComponent("info-filled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, null, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contactsDropdownResponse, (contact, index) => {
          return (_openBlock(), _createElementBlock("div", { key: index }, [
            _createVNode(_component_el_row, { gutter: 10 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, {
                  xs: 12,
                  sm: 12,
                  md: 12,
                  lg: 12,
                  xl: 12
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_row, {
                      type: "flex",
                      justify: "space-between",
                      align: "middle"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("h4", null, [
                            _createElementVNode("strong", null, "Contact " + _toDisplayString(index + 1) + " Name", 1),
                            _createVNode(_component_el_tooltip, { placement: "right" }, {
                              content: _withCtx(() => [
                                _createTextVNode(" Contact " + _toDisplayString(index + 1) + " Name ", 1)
                              ]),
                              default: _withCtx(() => [
                                _createVNode(_component_el_icon, {
                                  class: "el-text__grey",
                                  style: {"font-size":"small"}
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_info_filled)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _createVNode(_component_el_input, {
                            modelValue: _ctx.contactsDropdownResponse[index].name,
                            "onUpdate:modelValue": ($event: any) => ((_ctx.contactsDropdownResponse[index].name) = $event),
                            onBlur: ($event: any) => (_ctx.upsertCustomerContact(index))
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "onBlur"])
                        ]),
                        _createVNode(_component_el_button, { type: "text" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_font_awesome_icon, { icon: "pencil-alt" })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_el_col, {
                  xs: 12,
                  sm: 12,
                  md: 12,
                  lg: 12,
                  xl: 12
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_row, {
                      type: "flex",
                      justify: "space-between",
                      align: "middle"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("h4", null, [
                            _createElementVNode("strong", null, "Contact " + _toDisplayString(index + 1) + " Title", 1),
                            _createVNode(_component_el_tooltip, { placement: "right" }, {
                              content: _withCtx(() => [
                                _createTextVNode(" Contact " + _toDisplayString(index + 1) + " Title ", 1)
                              ]),
                              default: _withCtx(() => [
                                _createVNode(_component_el_icon, {
                                  class: "el-text__grey",
                                  style: {"font-size":"small"}
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_info_filled)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _createVNode(_component_el_input, {
                            modelValue: _ctx.contactsDropdownResponse[index].title,
                            "onUpdate:modelValue": ($event: any) => ((_ctx.contactsDropdownResponse[index].title) = $event),
                            onBlur: ($event: any) => (_ctx.upsertCustomerContact(index))
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "onBlur"])
                        ]),
                        _createVNode(_component_el_button, { type: "text" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_font_awesome_icon, { icon: "pencil-alt" })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_el_row, { gutter: 10 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, {
                  xs: 12,
                  sm: 12,
                  md: 12,
                  lg: 12,
                  xl: 12
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_row, {
                      type: "flex",
                      justify: "space-between",
                      align: "middle"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("h4", null, [
                            _createElementVNode("strong", null, "Contact " + _toDisplayString(index + 1) + " Email", 1),
                            _createVNode(_component_el_tooltip, { placement: "right" }, {
                              content: _withCtx(() => [
                                _createTextVNode(" Contact " + _toDisplayString(index + 1) + " Email ", 1)
                              ]),
                              default: _withCtx(() => [
                                _createVNode(_component_el_icon, {
                                  class: "el-text__grey",
                                  style: {"font-size":"small"}
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_info_filled)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _createVNode(_component_el_input, {
                            type: "email",
                            modelValue: _ctx.contactsDropdownResponse[index].mail,
                            "onUpdate:modelValue": ($event: any) => ((_ctx.contactsDropdownResponse[index].mail) = $event),
                            onBlur: ($event: any) => (_ctx.upsertCustomerContact(index))
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "onBlur"])
                        ]),
                        _createVNode(_component_el_button, { type: "text" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_font_awesome_icon, { icon: "pencil-alt" })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_el_col, {
                  xs: 12,
                  sm: 12,
                  md: 12,
                  lg: 12,
                  xl: 12
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_row, {
                      type: "flex",
                      justify: "space-between",
                      align: "middle"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("h4", null, [
                            _createElementVNode("strong", null, "Contact " + _toDisplayString(index + 1) + " Phone", 1),
                            _createVNode(_component_el_tooltip, { placement: "right" }, {
                              content: _withCtx(() => [
                                _createTextVNode(" Contact " + _toDisplayString(index + 1) + " Phone ", 1)
                              ]),
                              default: _withCtx(() => [
                                _createVNode(_component_el_icon, {
                                  class: "el-text__grey",
                                  style: {"font-size":"small"}
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_info_filled)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _createVNode(_component_el_input, {
                            type: "tel",
                            modelValue: _ctx.contactsDropdownResponse[index].mobile,
                            "onUpdate:modelValue": ($event: any) => ((_ctx.contactsDropdownResponse[index].mobile) = $event),
                            onBlur: ($event: any) => (_ctx.upsertCustomerContact(index))
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "onBlur"])
                        ]),
                        _createVNode(_component_el_button, { type: "text" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_font_awesome_icon, { icon: "pencil-alt" })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_el_divider)
          ]))
        }), 128)),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "success",
              onClick: _ctx.addCustomerContact
            }, {
              default: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, { icon: "plus-circle" }),
                _createTextVNode(" Add Customer Contact ")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}