import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_autocomplete = _resolveComponent("el-autocomplete")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_tabs, {
      ref: "tabRef",
      modelValue: _ctx.activeColumn,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.activeColumn) = $event)),
      "tab-position": 'left',
      style: {"height":"350px"},
      onTabClick: _ctx.onTabChange
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column, index) => {
          return (_openBlock(), _createBlock(_component_el_tab_pane, {
            key: index,
            label: column.header_text,
            name: column.id
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form, {
                onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_autocomplete, {
                        modelValue: _ctx.columnQueryValue,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.columnQueryValue) = $event)),
                        "fetch-suggestions": _ctx.queryColumnData,
                        "popper-append-to-body": true,
                        placeholder: "Please select a value",
                        onSelect: _ctx.addFilter,
                        ref_for: true,
                        ref: el => { _ctx.filterInputs[column.id] = el }
                      }, {
                        default: _withCtx(({ item }) => [
                          _createElementVNode("div", _hoisted_1, [
                            (column.type === 'date')
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _createTextVNode(_toDisplayString(item.label
                        ? _ctx.moment.utc(item.label).format("MM-DD-YYYY")
                        : ''), 1)
                                ], 64))
                              : (column.type === 'money')
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                    _createTextVNode(_toDisplayString(item.label ?
                        new Intl.NumberFormat(
                          'en-US', { style: 'currency', currency: 'USD' }
                        ).format(item.label)
                        :
                        ''), 1)
                                  ], 64))
                                : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                    _createTextVNode(_toDisplayString(item.label), 1)
                                  ], 64))
                          ])
                        ]),
                        _: 2
                      }, 1032, ["modelValue", "fetch-suggestions", "onSelect"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_el_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, {
                    lg: 8,
                    md: 8,
                    sm: 24,
                    xl: 8,
                    xs: 24
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters.filter((el) => el.visible), (filter, filterIndex) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: filterIndex,
                          class: "el-bordered__input el-padding__left el-padding__right el-margin__small"
                        }, [
                          _createVNode(_component_el_row, {
                            gutter: 0,
                            align: "middle",
                            class: "el-capitalize",
                            justify: "space-between",
                            type: "flex"
                          }, {
                            default: _withCtx(() => [
                              (column.type === 'date')
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    _createTextVNode(_toDisplayString(filter.filter_text
                      ? _ctx.moment.utc(filter.filter_text).format("MM-DD-YYYY")
                      : ''), 1)
                                  ], 64))
                                : (column.type === 'money')
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                      _createTextVNode(_toDisplayString(filter.filter_text ?
                      new Intl.NumberFormat(
                        'en-US', { style: 'currency', currency: 'USD' }
                      ).format(Number(filter.filter_text))
                      :
                      ''), 1)
                                    ], 64))
                                  : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                      _createTextVNode(_toDisplayString(filter.filter_text), 1)
                                    ], 64)),
                              _createVNode(_component_el_button, {
                                class: "el-text__danger",
                                icon: "el-icon-close",
                                type: "text",
                                onClick: ($event: any) => (_ctx.removeFilter(filter))
                              }, null, 8, ["onClick"])
                            ]),
                            _: 2
                          }, 1024)
                        ]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["label", "name"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "onTabClick"])
  ]))
}