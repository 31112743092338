import { useStore } from 'vuex';

import { computed, ComputedRef } from 'vue';

import { IFavoritesResponse } from '@/interfaces/Favorite';

/**
 *
 *
 * @export
 * @return {*}  {{
 *   favoritesResponse: ComputedRef<IFavoritesResponse>;
 *   fetchFavorites: () => Promise<void>;
 *   createFavorite: (payload: {
 *       viewName: string;
 *       favoriteName: string;
 *   }) => Promise<void>;
 *   }}
 */
export default function useFavorite(): {
  favoritesResponse: ComputedRef<IFavoritesResponse>;
  fetchFavorites: () => Promise<void>;
  createFavorite: (payload: {
      viewName: string;
      favoriteName: string;
  }) => Promise<void>;
  } {
  const store = useStore();

  const favoritesResponse = computed(
    (): IFavoritesResponse => store.getters['Favorite/getFavoritesResponse'],
  );

  /**
   *
   *
   * @return {*}  {Promise<void>}
   */
  async function fetchFavorites(): Promise<void> {
    try {
      await store.dispatch('Favorite/fetchFavorites');
    } catch (error) {
      console.error(error);
    }
  }

  /**
   *
   *
   * @param {{ viewName: string; favoriteName: string }} payload
   * @return {*}  {Promise<void>}
   */
  async function createFavorite(
    payload: { viewName: string; favoriteName: string },
  ): Promise<void> {
    try {
      await store.dispatch('Favorite/createFavorite', payload);
    } catch (error) {
      console.error(error);
    }
  }

  return {
    favoritesResponse,
    fetchFavorites,
    createFavorite,
  };
}
