import { computed, ComputedRef } from 'vue';
import { useStore } from 'vuex';

/**
 * Dataset Composition API module
 */
export default function useDataset(): {
  operations: ComputedRef<string[]>;
  functions: ComputedRef<string[]>;
  fetchDatasetOperations: () => Promise<void>;
  fetchDatasetFunctions: () => Promise<void>;
  } {
  const store = useStore();

  const operations = computed((): string[] => store.getters['Dataset/getDatasetOperations']);

  const functions = computed((): string[] => store.getters['Dataset/getDatasetFunctions']);

  /**
   *
   *
   * @return {*}  {Promise<void>}
   */
  async function fetchDatasetOperations(): Promise<void> {
    try {
      await store.dispatch('Dataset/fetchDatasetOperations');
    } catch (error) {
      console.error(error);
    }
  }

  /**
   *
   *
   * @return {*}  {Promise<void>}
   */
  async function fetchDatasetFunctions(): Promise<void> {
    try {
      await store.dispatch('Dataset/fetchDatasetFunctions');
    } catch (error) {
      console.error(error);
    }
  }

  return {
    operations,
    functions,
    fetchDatasetOperations,
    fetchDatasetFunctions,
  };
}
