
import { defineComponent } from 'vue';

import useView from '@/uses/useView';

import DashboardLayout from '@/layouts/DashboardLayout.vue';

export default defineComponent({
  name: 'ListDashboards',

  components: {
    DashboardLayout,
  },

  setup() {
    const { views } = useView();

    return {
      views,
    };
  },

});

