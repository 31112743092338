import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, null, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              placeholder: "Title",
              modelValue: _ctx.titleValue,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.titleValue) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              placeholder: "Note",
              modelValue: _ctx.noteValue,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.noteValue) = $event)),
              rows: 4,
              type: "textarea"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "success",
          onClick: _ctx.saveNote
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, { icon: "plus-circle" }),
            _createTextVNode(" Add New ")
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_el_divider),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notesDropdownResponse, (note, index) => {
          return (_openBlock(), _createElementBlock("div", { key: index }, [
            _createElementVNode("strong", null, [
              _createVNode(_component_font_awesome_icon, { icon: "sticky-note" }),
              _createTextVNode(" " + _toDisplayString(note.title), 1)
            ]),
            _createElementVNode("p", null, _toDisplayString(note.notes), 1),
            _createVNode(_component_el_divider)
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}