import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_openBlock(), _createBlock(_component_el_scrollbar, { "max-height": "400px" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form, { inline: true }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                placeholder: "Select Field",
                modelValue: _ctx.filter.id,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter.id) = $event))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column, columnIndex) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: columnIndex,
                      label: column.header_text,
                      value: column.id
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                placeholder: "Select Operand",
                modelValue: _ctx.filter.operation,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filter.operation) = $event))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.operations, (operation, operationIndex) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: operationIndex,
                      label: operation,
                      value: operation
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                placeholder: "Enter Value",
                modelValue: _ctx.filter.filter_text,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filter.filter_text) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "success",
                icon: "el-icon-plus",
                disabled: _ctx.canAddFilter,
                onClick: _ctx.addFilter
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" add ")
                ]),
                _: 1
              }, 8, ["disabled", "onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.gridRequest.filters && _ctx.gridRequest.filters.length > 0)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.gridRequest.filters.filter((filter) => filter.visible), (gridRequestFilter, filterIndex) => {
            return (_openBlock(), _createElementBlock("div", { key: filterIndex }, [
              _createVNode(_component_el_form, { inline: true }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, {
                        modelValue: gridRequestFilter.id,
                        "onUpdate:modelValue": ($event: any) => ((gridRequestFilter.id) = $event),
                        disabled: true
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column, columnIndex) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: columnIndex,
                              label: column.header_text,
                              value: column.id
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1032, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_el_form_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: gridRequestFilter.operation,
                        "onUpdate:modelValue": ($event: any) => ((gridRequestFilter.operation) = $event),
                        disabled: ""
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_el_form_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: gridRequestFilter.filter_text,
                        "onUpdate:modelValue": ($event: any) => ((gridRequestFilter.filter_text) = $event),
                        disabled: ""
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_el_form_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_button, {
                        type: "text",
                        icon: "el-icon-close",
                        class: "el-text__danger",
                        onClick: ($event: any) => (_ctx.removeFilter(gridRequestFilter))
                      }, null, 8, ["onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]))
          }), 128))
        : (_openBlock(), _createBlock(_component_el_empty, {
            key: 1,
            description: "No Filters Created"
          }))
    ]),
    _: 1
  }))
}