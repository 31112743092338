import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    title: "Favorites Form",
    modelValue: _ctx.isDialogOpen,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isDialogOpen) = $event)),
    onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isDialogOpen = false))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.$emit('save-favorite', _ctx.name)), ["prevent"]))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, { label: "Name" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "primary",
                "native-type": "submit"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Save Favorite ")
                ]),
                _: 1
              }),
              _createVNode(_component_el_button, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isDialogOpen = false))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Cancel ")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}