
import {
  computed, defineComponent, onMounted, PropType, ref,
} from 'vue';
import { useRoute } from 'vue-router';

import moment from 'moment';

import { IComponentResponse } from '@/interfaces/View';
import { IGridResponse } from '@/interfaces/Grid';
import { IDrilldownRequest } from '@/interfaces/Drilldown';

import useGrid from '@/uses/useGrid';
import useView from '@/uses/useView';
import { IOrderRequest } from '@/interfaces/Order';

interface ISortOrder {
  prop: string;
  order: string | null;
}

export default defineComponent({
  name: 'DataGrid',

  props: {
    drilldown: {
      type: Object as PropType<IComponentResponse>,
      required: true,
    },

    drilldownDataRow: {
      type: Object as PropType<Record<string, unknown>>,
      required: true,
    },

    drilldownNextLevel: {
      type: Object as PropType<IComponentResponse | undefined>,
      required: false,
      default: undefined,
    },
  },

  setup(props) {
    const dataRow = ref<Record<string, unknown>>({});

    const route = useRoute();

    const expandRowKeys = ref<string[]>([]);

    const drilldownRequest = ref<IDrilldownRequest>({
      page_size: 100,
      page_index: 0,
      data_row: {},
      orders: [],
    });

    const drilldownResponse = ref<IGridResponse>({
      success: false,
      dataset_name: '',
      totals: {},
      fields: [],
      rows: [],
      pagination: {
        page_size: 100,
        page_index: 0,
        page_count: 0,
        total_rows: 0,
      },
    });

    const pages = ref([10, 50, 100, 500, 1000, 2000]);

    // eslint-disable-next-line camelcase
    const page_size = ref<number>(10);

    const sortOrder = ref<ISortOrder>({
      prop: '',
      order: '',
    });

    const canChangePage = computed(
      (): boolean => drilldownResponse.value.pagination.page_count === (
        drilldownResponse.value.pagination.page_index + 1),
    );

    onMounted(() => {
      page_size.value = drilldownResponse.value.pagination.page_size;
    });

    const { fetchDrilldownData } = useGrid();

    const { view } = useView();

    const toggleDrilldown = (payload): void => {
      if (
        expandRowKeys.value
        && expandRowKeys.value.length > 0
        && payload.id === expandRowKeys.value[0]
      ) {
        expandRowKeys.value = [];
      } else {
        expandRowKeys.value = [payload.id];
      }
      dataRow.value = payload;
    };

    const changeDataRow = async (payload: Record<string, unknown>): Promise<void> => {
      try {
        drilldownRequest.value.data_row = payload;
      } catch (error) {
        console.error(error);
      }
    };

    const changePage = async (page: number): Promise<void> => {
      try {
        drilldownRequest.value.page_index = page;
        drilldownResponse.value = await fetchDrilldownData({
          viewName: view.value.name,
          drilldownName: props.drilldown.name,
          drilldownRequest: drilldownRequest.value,
        });
      } catch (error) {
        console.error(error);
      } finally {
        if (route.query.dataRow) {
          const elements = document.getElementsByClassName('el-table__body-wrapper');
          if (elements) {
            elements[0].scrollTo(0, 0);
          }
          const main = document.getElementsByClassName('el-main');
          if (main) {
            main[0].scrollTo(0, 0);
          }
        }
      }
    };

    const changeLimit = async (limit: number): Promise<void> => {
      try {
        drilldownRequest.value.page_size = limit;
        drilldownResponse.value = await fetchDrilldownData({
          viewName: view.value.name,
          drilldownName: props.drilldown.name,
          drilldownRequest: drilldownRequest.value,
        });
      } catch (error) {
        console.error(error);
      }
    };

    const changeSort = async ({ order, prop }: {order: string; prop: string}): Promise<void> => {
      try {
        sortOrder.value.prop = prop;
        sortOrder.value.order = order;
        if (order) {
          const sort: IOrderRequest = {
            id: prop,
            type: order === 'ascending' ? 'asc' : 'desc',
          };
          drilldownRequest.value.orders = [sort];
          drilldownResponse.value = await fetchDrilldownData({
            viewName: view.value.name,
            drilldownName: props.drilldown.name,
            drilldownRequest: drilldownRequest.value,
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getSummaries = (param) => {
      const { columns } = param;
      const sums: string[] = [];
      if (drilldownResponse.value.totals) {
        const summableColumns = Object.keys(drilldownResponse.value.totals);
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '';
            return;
          }
          if (summableColumns.includes(column.property)) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            sums[index] = `${props.drilldown.columns[index]?.prefix ?? ''} ${Number(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
              drilldownResponse.value.totals[column.property],
            ).toFixed(2)}`;
          } else {
            sums[index] = '';
          }
        });
      }
      return sums;
    };

    return {
      view,
      dataRow,
      drilldownResponse,
      drilldownRequest,
      pages,
      page_size,
      expandRowKeys,
      canChangePage,
      sortOrder,
      toggleDrilldown,
      changeDataRow,
      changePage,
      changeLimit,
      changeSort,
      fetchDrilldownData,
      getSummaries,
      moment,
    };
  },

  watch: {
    drilldownDataRow: {
      deep: true,
      immediate: true,
      async handler(): Promise<void> {
        try {
          this.changeDataRow(this.drilldownDataRow);
          const response = await this.fetchDrilldownData({
            viewName: this.view.name,
            drilldownName: this.drilldown.name,
            drilldownRequest: this.drilldownRequest,
          });
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          response.rows = response.rows.map((el, index) => ({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...el,
            id: index,
          }));
          this.drilldownResponse = response;
        } catch (error) {
          console.error(error);
        }
      },
    },
  },
});
