export default async function poster(url) {
  const res = await fetch(`${process.env.VUE_APP_API_BASE_URL}${url}`, {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
    body: JSON.stringify({
      page_size: 100,
      page_index: 0,
      orders: [],
      filters: [],
      drillthrough: null,
    }),
  });

  return res.json();
}
