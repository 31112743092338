
import { defineComponent, onMounted, ref } from 'vue';

import { useRoute } from 'vue-router';

import { ElLoading } from 'element-plus';

import { CustomerContacts, CustomerNotes } from '@/components/Customer';
import DashboardLayout from '@/layouts/DashboardLayout.vue';
import { Documents } from '@/components/Shared';

import useCustomer from '@/uses/useCustomer';

export default defineComponent({
  name: 'Customer',

  components: {
    CustomerContacts,
    CustomerNotes,
    DashboardLayout,
    Documents,
  },

  setup() {
    const route = useRoute();

    const { fetchCustomer, customer } = useCustomer();

    const activeCollapseItem = ref<string[]>(['details', 'contacts', 'notes']);

    onMounted(async () => {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      try {
        await fetchCustomer({
          viewName: 'customers',
          gridName: 'customer_grid',
          gridRequest: {
            page_size: 1,
            page_index: 0,
            orders: [],
            filters: [{
              id: 'external_id',
              type: 'dimension',
              index: 0,
              visible: true,
              field_id: 'external_id',
              operation: '=',
              data_source: 'customer',
              filter_text: route.params.customerNo.toString(),
              header_text: 'External ID',
              default_filter: '',
            }],
            drillthrough: null,
          },
        });
      } catch (error) {
        console.error(error);
      } finally {
        loadingInstance.close();
      }
    });

    return {
      customer,
      activeCollapseItem,
    };
  },

  watch: {
    customer: {
      handler() {
        if (this.customer.external_id) {
          document.title = `Customer ${this.customer.external_id} Detail`;
        }
      },
      deep: true,
      immediate: true,
    },
  },
});
