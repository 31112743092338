import { useStore } from 'vuex';
import { computed, ComputedRef } from 'vue';

import {
  ICustomerRequest,
  ICustomerResponse,
} from '@/interfaces/Customer';

export default function useGrid(): {
  customer: ComputedRef<ICustomerResponse>;
  fetchCustomer: (payload: {
    viewName: string;
    gridName: string;
    gridRequest: ICustomerRequest;
  }) => Promise<void>;
  } {
  const store = useStore();

  const customer = computed((): ICustomerResponse => store.getters['Customer/getCustomerResponse']);

  async function fetchCustomer(
    payload: {viewName: string; gridName: string; gridRequest: ICustomerRequest},
  ): Promise<void> {
    try {
      await store.dispatch('Customer/fetchCustomer', payload);
    } catch (error) {
      console.error(error);
    }
  }
  return {
    customer,
    fetchCustomer,
  };
}
