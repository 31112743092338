export default async function fetcher(url) {
  const res = await fetch(`${process.env.VUE_APP_API_BASE_URL}${url}`, {
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  });

  if (!res.ok) {
    throw res;
  }

  return res.json();
}
