
import {
  defineComponent, ref, PropType, onMounted, computed,
} from 'vue';

import { IPaginationResponse } from '@/interfaces/Pagination';

export default defineComponent({
  name: 'DataGridPagination',

  props: {
    pagination: {
      type: Object as PropType<IPaginationResponse>,
      required: true,
    },
  },

  emits: ['change-page', 'change-limit'],

  setup(props) {
    const pages = ref([10, 50, 100, 500, 1000, 2000]);

    // eslint-disable-next-line camelcase
    const page_size = ref<number>(100);

    const canChangePage = computed(
      (): boolean => props.pagination.page_count === (props.pagination.page_index + 1),
    );

    onMounted(() => {
      page_size.value = props.pagination.page_size;
    });

    return { page_size, pages, canChangePage };
  },

});
