
import {
  computed, defineComponent, ref, PropType,
} from 'vue';

import draggable from 'vuedraggable';

import useView from '@/uses/useView';

import { IColumn } from '@/interfaces/View';

export default defineComponent({
  name: 'ColumnsTab',

  components: {
    draggable,
  },

  props: {
    columns: {
      type: Array as PropType<IColumn[]>,
      required: true,
    },
  },

  setup() {
    const {
      datasetColumns,
      updateColumns,
    } = useView();

    const dimensions = ref<IColumn[]>([]);

    const searchableColumns = computed((): IColumn[] => {
      const columnSet = [...datasetColumns.value];
      const selectedColumnsIds = dimensions.value.map((el) => el.id);
      return columnSet.filter((column: IColumn) => !selectedColumnsIds.includes(column.id));
    });

    const datasetQueryValue = ref<string>('');

    const selectedColumnId = ref<number | null>();

    const selectedColumn = ref<string>('');

    const drag = ref<boolean>(false);

    const createDatasetFilter = (queryString: string) => (column: IColumn) => (
      column.header_text.toLowerCase().indexOf(queryString.toLowerCase()) > -1
    );

    const queryDataset = (queryString: string, cb) => {
      const results = queryString
        ? searchableColumns.value?.filter(createDatasetFilter(queryString))
        : searchableColumns.value;
      // call callback function to return suggestions
      cb(results?.map((el: IColumn) => ({ ...el, value: el.header_text })));
    };

    const addColumn = (selectedField): void => {
      try {
        const newColumn = { ...selectedField };
        delete newColumn?.value;
        newColumn.index = dimensions.value.length + 1;
        dimensions.value.push(newColumn);
      } catch (error) {
        console.error(error);
      } finally {
        datasetQueryValue.value = '';
      }
    };

    const removeColumn = (selectedFieldId: string): void => {
      const index: number = dimensions.value.findIndex(
        (column) => column.id === selectedFieldId,
      );
      dimensions.value.splice(index, 1);
    };

    const reorderColumns = (): void => {
      try {
        dimensions.value = dimensions.value.map(
          (element, newIndex) => ({ ...element, index: newIndex }),
        );
      } catch (error) {
        console.error(error);
      }
    };

    const setColumns = (): void => {
      try {
        updateColumns(dimensions.value);
      } catch (error) {
        console.error(error);
      }
    };

    return {
      datasetQueryValue,
      selectedColumnId,
      selectedColumn,
      dimensions,
      drag,
      queryDataset,
      addColumn,
      removeColumn,
      setColumns,
      reorderColumns,
    };
  },

  watch: {
    columns: {
      handler(value) {
        this.dimensions = value;
      },
      deep: true,
      immediate: true,
    },
  },
});

