
import { defineComponent, ref, PropType } from 'vue';

import { ElLoading, ElTabs } from 'element-plus';

import { IColumn } from '@/interfaces/Grid';

import useView from '@/uses/useView';
import useGrid from '@/uses/useGrid';

import AdvancedFiltersTab from './AdvancedFiltersTab.vue';
import ColumnsTab from './ColumnsTab.vue';
import FiltersTab from './FiltersTab.vue';

export default defineComponent({
  name: 'CustomizeDialog',

  components: {
    AdvancedFiltersTab,
    ColumnsTab,
    FiltersTab,
  },

  props: {
    name: {
      type: String,
      required: true,
    },

    description: {
      type: String,
      required: true,
    },

    columns: {
      type: Array as PropType<IColumn[]>,
      required: true,
    },
  },

  emits: ['query-grid-data', 'reset-data-grid'],

  setup(props, { emit }) {
    const {
      view, dataSource, gridName, resetView, fetchView, setGridFilters,
    } = useView();

    const { gridRequest, fetchGridData, resetGridRequest } = useGrid();

    const isDialogOpen = ref<boolean>(false);

    const activeTab = ref<string>('columns');

    const advancedFiltersTab = ref();

    const columnsTab = ref();

    const metricsTab = ref();

    const filtersTab = ref();

    const customizeDialog = ref<InstanceType<typeof ElTabs>>();

    const open = ():void => {
      isDialogOpen.value = true;
    };

    const resetToDefault = async (): Promise<void> => {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      try {
        resetGridRequest();
        await resetView(view.value.name);
        await fetchView(view.value.name);
        if (dataSource.value && gridName.value) {
          await fetchGridData({
            viewName: view.value.name,
            gridName: gridName.value,
            gridRequest: gridRequest.value,
          });
        }
        emit('reset-data-grid');
      } catch (error) {
        console.error(error);
      } finally {
        isDialogOpen.value = false;
        loadingInstance.close();
      }
    };

    const handleTabClick = () => {
      columnsTab.value.setColumns();
    };

    const saveConfigurations = () => {
      columnsTab.value.setColumns();
      isDialogOpen.value = false;
      setGridFilters(gridRequest.value.filters);
      emit('query-grid-data');
    };

    return {
      customizeDialog,
      isDialogOpen,
      activeTab,
      advancedFiltersTab,
      columnsTab,
      metricsTab,
      filtersTab,
      open,
      resetToDefault,
      handleTabClick,
      saveConfigurations,
    };
  },
});
